<template>
  <el-select
    v-model="acIds"
    :placeholder="__('Select Account')"
    filterable
    collapse-tags
    style="width: 100%"
    default-first-option
    :multiple="multiSelect"
    :no-data-text="__('No accounts found')"
    :disabled="disabled || !hasMultipleAccountsInServiceProvider(selectedSpId)"
  >
    <el-option
      v-if="hasMultipleAccountsInServiceProvider(selectedSpId)"
      :label="__('All Accounts')"
      value="all"
    ></el-option>
    <el-option
      v-for="account in userAccountsFor(selectedSpId)"
      :key="account.ac_id"
      :label="account.ac_name"
      :value="account.ac_id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "AccountDropdown",
  props: {
    selectedSpId: {
      required: false,
      default: "all"
    },
    defaultAcId: {
      required: false,
      type: Array
    },
    multiSelect: {
      default: true,
      type: Boolean
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      acIds: ["all"]
    };
  },
  computed: {
    ...mapState("app", {
      serviceProvidersAccessibleToUser: state =>
        state.userAccountSwitcherOptions
    }),

    ...mapGetters("app", {
      userAccountsFor: "userAccountsFor",
      hasMultipleAccountsInServiceProvider:
        "hasMultipleAccountsInServiceProvider"
    })
  },

  watch: {
    selectedSpId: {
      immediate: true,
      handler: function() {
        this.acIds = _.cloneDeep(this.defaultAcId);
      }
    },

    defaultAcId: {
      immediate: true,
      deep: true,
      handler: function(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          this.acIds = _.cloneDeep(this.defaultAcId);
        }
      }
    },

    acIds: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          let newlyAdded = _.difference(newVal, oldVal);
          if (_.includes(newlyAdded, "all")) {
            this.acIds = ["all"];
          } else if (
            _.includes(oldVal, "all") &&
            _.includes(newVal, "all") &&
            _.size(newVal) > 1
          ) {
            this.acIds = _.reject(newVal, item => item === "all");
          }

          this.$emit("ac-id-changed", this.acIds);
        }
      }
    }
  }
};
</script>

<style scoped></style>
