<template>
  <el-select
    v-model="taskIds"
    :placeholder="taskPlaceHolder"
    filterable
    collapse-tags
    style="width: 100%"
    v-loading="isLoading"
    default-first-option
    :no-data-text="noTextData"
    :multiple="multiSelect"
    :disabled="!selectedAcId.length || totalAccessibleTasks === 1"
  >
    <el-option-group v-if="totalAccessibleTasks > 1" value="all">
      <el-option :label="__('All Tasks')" value="all"></el-option>
      <el-option :label="__('No Task')" value="none"></el-option>
    </el-option-group>
    <el-option-group
      v-for="account in tasksList"
      :key="account.ac_id"
      :label="account.ac_name"
    >
      <el-option
        v-for="task in account.tasks"
        :key="task.task_id"
        :label="task.task_name"
        :value="task.task_id"
      >
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  name: "TaskDropdown",
  props: {
    selectedSpId: {
      required: true,
      type: [String, Number]
    },
    selectedAcId: {
      required: true,
      type: Array
    },
    defaultTaskId: {
      required: false,
      type: Array,
      default: () => ["all"]
    },
    multiSelect: {
      default: true,
      type: Boolean
    },
    taskType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      taskIds: ["all", "none"],
      tasksList: []
    };
  },
  computed: {
    ...mapState("tasks", {
      isLoading: state => state.loading
    }),

    totalAccessibleTasks() {
      return _.reduce(
        this.tasksList,
        function(sum, account) {
          return sum + account.tasks.length;
        },
        0
      );
    },

    accountWithTasks() {
      return _.find(this.tasksList, account => account.tasks.length > 0);
    },

    firstFoundTask() {
      let account = this.accountWithTasks;
      if (account) {
        return account.tasks[0];
      } else {
        return { task_id: "all" };
      }
    },

    taskPlaceHolder() {
      if (!this.selectedAcId.length) {
        return __("Select Account first");
      }
      return __("Select Task");
    },
    noTextData() {
      return __("No :tasktype tasks found", {
        tasktype: this.taskType
      });
    }
  },
  methods: {
    ...mapActions("tasks", {
      getTasksForAccounts: "getTasksForAccounts"
    }),

    updateTasksList(sp_id, ac_ids, task_type) {
      this.getTasksForAccounts({
        spId: sp_id,
        accIds: ac_ids.join(","),
        taskType: task_type
      }).then(({ data }) => {
        this.tasksList = data;
        if (this.totalAccessibleTasks === 1) {
          this.taskIds = [this.firstFoundTask.task_id];
        } else if (!this.totalAccessibleTasks) {
          this.taskIds = [];
        }
      });
    }
  },

  watch: {
    taskIds: {
      handler: function(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          let newlyAdded = _.difference(newVal, oldVal);
          let selectedTaskIds = _.cloneDeep(newVal);
          if (_.includes(newlyAdded, "all")) {
            selectedTaskIds = ["all"];
          } else if (_.includes(newlyAdded, "none")) {
            selectedTaskIds = ["none"];
          } else if (
            _.includes(oldVal, "all") &&
            _.includes(newVal, "all") &&
            _.size(newVal) > 1
          ) {
            selectedTaskIds = _.reject(newVal, item => item === "all");
          }
          this.$emit("task-id-changed", _.cloneDeep(selectedTaskIds));
        }
      }
    },
    taskType: {
      handler: function(val) {
        this.updateTasksList(this.selectedSpId, this.selectedAcId, val);
      }
    },
    selectedAcId: {
      immediate: true,
      deep: true,
      handler: function(newVal, oldVal) {
        let selectedTaskIds = _.cloneDeep(this.taskIds);
        if (!_.isEqual(newVal, oldVal) && newVal.length) {
          if (newVal.includes("all")) {
            selectedTaskIds = ["all"];
          }
          this.updateTasksList(this.selectedSpId, newVal, this.taskType);
        } else if (!newVal.length) {
          selectedTaskIds = [];
        }
        this.taskIds = _.cloneDeep(selectedTaskIds);
      }
    },
    defaultTaskId: {
      immediate: true,
      deep: true,
      handler: function(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          this.taskIds = _.cloneDeep(this.defaultTaskId);
        }
      }
    }
  }
};
</script>

<style scoped></style>
