<template>
  <el-select
    v-model="spID"
    :placeholder="__('Please select Service Provider')"
    filterable
    style="width: 100%"
    default-first-option
    @change="changeSpId"
  >
    <el-option
      v-for="sp in serviceProvidersAccessibleToUser"
      :key="sp.sp_id"
      :label="sp.sp_name"
      :value="sp.sp_id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ServiceProviderDropdown",
  props: {
    defaultSpId: {
      required: false,
      type: [String, Number],
      default: "all"
    }
  },
  data() {
    return {
      spID: "all"
    };
  },
  computed: {
    ...mapState("app", {
      serviceProvidersAccessibleToUser: state =>
        state.userAccountSwitcherOptions
    })
  },

  methods: {
    changeSpId(spId) {
      this.$emit("sp-id-changed", spId);
    }
  },

  watch: {
    defaultSpId: {
      immediate: true,
      handler: function(val) {
        this.spID = val;
      }
    }
  }
};
</script>

<style scoped></style>
